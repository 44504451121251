/* website: 3351-spinellilexuslachine1
 * created at 2021-04-05 4:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/lexus.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
    .widget-sr {
        &.dealer__lexus-spinelli-lachine {
        .LoginBanner{
            padding: 0px!important;
        }
        .LoginBanner .LoginBannerWrapper {
          height: 34px;
          max-height: 34px;
          .login-content {
            display: flex;
            padding-right: 0;
            #button-login,
            #button-register {
              font-size: 12px !important;
              .label{
                font-size: 12px !important;
              }
            }
  
            #button-login.btn-login {
              margin-left: 10px;
            }
  
            #button-register.btn-register {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__lexus-spinelli-lachine{
        .listing-tile .listing-tile-image .car-info{
            position: relative;
        }
        .listing-tile .listing-tile-image:before{
          background: linear-gradient(180deg,hsla(0,0%,100%,0) 53%,hsla(0,0%,98%,0) 100%,#000) !important;
        }
        .listing-tile .listing-tile-image .car-info .car-meta,
        .listing-tile .listing-tile-image .car-info .car-name,
        .listing-tile .listing-tile-image .car-tagline{
          text-shadow: none;
          color: #000000;
        }
        .listing-tile .listing-tile-image .car-info .picto-showroom.tile-tag{
          background: #000000!important;
        }
        .listing-tile .listing-tile-image{
            display:flex;
            flex-direction: column-reverse;
        }
        .listing-tile .listing-tile-image .car-clearance{
            bottom: unset;
            top: 50px;
            left: 20px;
            z-index: 1;
            border-radius:2px;
            font-weight: 700;
            line-height: 20px;
            padding: 3px 8px;
        }
        .certified-text.is-light{
            color: #333 !important;
        }
        .checkout__section-delivery input[type=radio]+label{
            text-transform: unset !important;
        }
        .showroom-build-and-price{
            margin-bottom:0;
        }
        /** fix place tradein at top of listing **/
        .listing-used-car-search>div:last-child{
            display: flex;
            flex-direction: column;
            #instant-estimate-bar-wrapper{
                order: 0;
                margin-top: 0px !important;
            }
            .listing-tiles{
                order: 1;
            }
            .listing-used-button-loading.sr-button-1{
                order: 2;
            }
        }
            /** fix AV widget padding on V2 VLP **/
        .listing-used-car-search{
            .av_widget_root{
                margin: 0 0 20px 0 !important;
            }
        }
    }
}

:lang(fr) .widget-sr{
    &.dealer__lexus-spinelli-lachine{
        .TransactionSummary--wrapper--title{
            span{
                display: none;
            }
            &:after{
                content: 'Votre transaction';
                font-family: 'nobelbold' ,sans-serif;
                font-weight: 700;
                align-self: center;
            }
        }
        #button-register{
            .label{
                display: none;
            }
            &:after{
                content:'Créez un compte';
            }
        }
    }
}